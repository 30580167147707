import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isValidEmail } from 'helpers/validation';
import { toast } from 'components/elements/molecules/Toast/Toast';

import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import TextArea from 'components/elements/atoms/FloatingTextarea/FloatingTextarea';
import * as Actions from '../../../redux/actions';
import { Icon, ICONS } from '../../Icons';

import './InviteStudents.scss';
import filterInvites from './filterInvites';
import DropDown from 'components/elements/atoms/DropDown/DropDown';
import DropDownWithAction from 'components/elements/organisms/DropDownWithAction/DropDownWithAction';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import { generateUniqueId } from 'services';
import LocationPicker from 'routes/Profile/components/LocationPicker/LocationPicker';

const generalDomains = ['gmail.com', 'hotmail.com', 'yahoo.com'];

function InviteStudents({
  user,
  onClose,
  courseId,
  actions,
  course,
  isTeacher = false,
}) {
  const [error, setError] = useState('');
  const [emails, setEmails] = useState('');
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSchoolAssign, setShowSchoolAssign] = useState(false);
  const [studentWithEmails, setStudentWithEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [confirmationCount, setConfirmationCount] = useState(0);
  const [confirmationEmails, setConfirmationEmails] = useState([]);
  const [newSchools, setNewSchools] = useState([]);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    if ((user.allSchools || []).length === 0) {
      actions
        .getSchools()
        .then(({ schools }) => {
          setSchools(schools);
        })
        .catch(() => {});
    } else {
      setSchools(user.allSchools);
    }
  }, []);

  useEffect(() => {
    if (
      showSchoolAssign &&
      Array.isArray(studentWithEmails) &&
      studentWithEmails.length === 0
    ) {
      setShowSchoolAssign(false);
      setEmails('');
      setShowWarning(false);
      setError('');
      setFilteredEmails([]);
      setConfirmationEmails([]);
      setConfirmationCount(0);
    }
  }, [studentWithEmails, showSchoolAssign]);

  const isAdminUser =
    user &&
    user.profile &&
    Array.isArray(user.profile.roles) &&
    user.profile.roles.findIndex((i) => i.type === 'admin');

  const schoolsOptions = useMemo(() => {
    const schoolsOptions = schools.map((s) => ({
      label: s.name,
      value: s._id,
    }));

    const newSchoolOptions = newSchools.map((s) => ({
      label: s.name,
      value: s._id,
    }));

    const updatedSchoolsOptions = [...schoolsOptions, ...newSchoolOptions];

    updatedSchoolsOptions.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    return [{ label: 'No school', value: null }, ...updatedSchoolsOptions];
  }, [schools, newSchools]);

  const checkSchool = (emails) => {
    let studentEmails = emails.map((email) => ({ email, schoolId: null }));
    if (schools.length > 0 && schools[0]._id) {
      studentEmails = assignSchoolToStudents(
        emails,
        user.email,
        schools[0]._id,
      );
    }

    setShowSchoolAssign(true);
    setStudentWithEmails(studentEmails);
  };

  const inviteUsers = (emails) => {
    setLoading(true);

    let promise = null;
    if (isTeacher) {
      promise = actions.inviteTeachers(courseId, emails, newSchools);
    } else {
      promise = actions.inviteStudents(courseId, emails, newSchools);
    }

    promise
      .then(() => {
        setLoading(false);

        toast(
          `Invitations sent to your ${isTeacher ? 'teacher(s)' : 'student(s)'}`,
          {
            type: 'success',
          },
        );
        onClose();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = (emails) => {
    const emailsArray = emails
      .split(/[\n,]/g)
      .map((e) => e.trim())
      .filter((e) => e);

    if (!emailsArray.length) {
      setError('Please enter valid emails');
      return;
    }

    const invalidEmail = emailsArray.find((e) => !isValidEmail(e));
    if (invalidEmail) {
      setError(`${invalidEmail} is invalid email`);
      return;
    }

    const { filteredInvites, alreadyInvited } = filterInvites(
      emailsArray,
      course,
      isTeacher,
    );

    if (!filteredInvites.length && !alreadyInvited.length) {
      return onClose();
    }

    if (filteredInvites.length && !alreadyInvited.length) {
      return checkSchool(filteredInvites);
    }

    setFilteredEmails([...filteredInvites]);
    setConfirmationEmails([...alreadyInvited]);
    setShowWarning(true);
  };

  const onConfirmation = (email) => {
    setFilteredEmails((prevEmails) => [...prevEmails, email]);

    showNext([...filteredEmails, email]);
  };

  const showNext = (filteredEmails) => {
    if (confirmationCount + 1 === confirmationEmails.length) {
      if (!filteredEmails.length) {
        return onClose();
      }

      return checkSchool(filteredEmails);
    }

    setConfirmationCount((prevCount) => prevCount + 1);
  };

  const addNewSchool = async (newSchool, email) => {
    try {
      const _id = await generateUniqueId();

      setNewSchools((newSchools) => {
        return [
          ...newSchools,
          { _id, ...newSchool, ...(newSchool.location || {}) },
        ];
      });

      setStudentWithEmails((studentWithEmails) => {
        const students = _.cloneDeep(studentWithEmails);
        const student = students.find((se) => se.email === email);

        student.schoolId = _id;

        return students;
      });
    } catch (error) {
      console.log('Error while adding new school', error);
    }
  };

  const showSchoolAssignment = () => {
    return (
      <div>
        <div className="my-2 av-gray">
          Please assign a school to the{' '}
          {isTeacher ? 'teacher(s)' : 'student(s)'}
        </div>
        <StudentSchoolAssignmentContainer
          studentWithEmails={studentWithEmails}
          actions={actions}
          schoolsOptions={schoolsOptions}
          setStudentWithEmails={setStudentWithEmails}
          isAdminUser={isAdminUser}
          addNewSchool={addNewSchool}
          isTeacher={isTeacher}
        />

        <div className="submit-container">
          {loading ? (
            <div className="text-center mt-4">
              <div
                className="fa fa-spin fa-spinner text-primary"
                style={{ fontSize: '40px' }}
              />
            </div>
          ) : (
            <RectangularButton
              className="mt-4"
              disabled={
                studentWithEmails.some((s) => !s.schoolId) && !isAdminUser
              }
              onClick={() => inviteUsers(studentWithEmails)}
            >
              Submit
            </RectangularButton>
          )}
        </div>
      </div>
    );
  };

  const showTextInput = () => (
    <React.Fragment>
      <div className="subscription-container my-5">
        <React.Fragment>
          <div className="my-4">
            <TextArea
              name="emails"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              labelName="Emails (Enter comma or new line separated emails)"
            ></TextArea>
          </div>
          {error && <div className="error-message my-3">{error}</div>}
        </React.Fragment>
      </div>
      <div className="submit-container">
        {loading ? (
          <div className="text-center mt-4">
            <div
              className="fa fa-spin fa-spinner text-primary"
              style={{ fontSize: '40px' }}
            />
          </div>
        ) : (
          <RectangularButton
            disabled={!emails}
            onClick={() => onSubmit(emails)}
          >
            Submit
          </RectangularButton>
        )}
      </div>
    </React.Fragment>
  );

  const showConfirmation = () => {
    const confirmationForUser = confirmationEmails[confirmationCount];

    return (
      <React.Fragment>
        <div className="my-3">
          {getConfirmationMessage(confirmationForUser, isTeacher)}
        </div>
        <div className="submit-container">
          {loading ? (
            <div className="text-center mt-4">
              <div
                className="fa fa-spin fa-spinner text-primary"
                style={{ fontSize: '40px' }}
              />
            </div>
          ) : (
            <React.Fragment>
              <RectangularButton
                className="mr-2"
                lightButton
                onClick={() => showNext(filteredEmails)}
              >
                Skip
              </RectangularButton>
              {!(
                (confirmationForUser.isTeacher && isTeacher) ||
                (!confirmationForUser.isTeacher && !isTeacher)
              ) && (
                <RectangularButton
                  darkButton
                  onClick={() => onConfirmation(confirmationForUser.email)}
                >
                  Confirm
                </RectangularButton>
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };

  const showContent = () => {
    if (showSchoolAssign) {
      return showSchoolAssignment();
    }

    if (showWarning) {
      return showConfirmation();
    }

    return showTextInput();
  };

  return (
    <Modal show={true} className="invite-students-modal" bsSize="lg">
      <Modal.Body>
        <div className="header">
          <span>Invite {isTeacher ? 'Teachers' : 'Students'}</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        {showContent()}
      </Modal.Body>
    </Modal>
  );
}

function StudentSchoolAssignmentContainer({
  studentWithEmails,
  actions,
  setStudentWithEmails,
  ...restProps
}) {
  const [loading, setLoading] = useState(true);

  async function updateSchools(studentWithEmails) {
    try {
      setLoading(true);

      const response = await actions.getSchoolsForUsers(
        studentWithEmails.map((e) => e.email).join(','),
      );

      const schoolsByEmail = response.schoolsByEmail;

      setStudentWithEmails((studentWithEmails) => {
        return studentWithEmails.map((studentWithEmail) => ({
          ...studentWithEmail,
          schoolId: schoolsByEmail[studentWithEmail.email]
            ? schoolsByEmail[studentWithEmail.email]
            : null,
        }));
      });
    } catch (error) {
      console.error(
        'Error while fetching schools of the existing users',
        error,
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loading) {
      updateSchools(studentWithEmails);
    }
  }, [loading]);

  if (loading) {
    return (
      <div className="text-center mt-4">
        <div
          className="fa fa-spin fa-spinner text-primary"
          style={{ fontSize: '40px' }}
        />
      </div>
    );
  }

  console.log('studentWithEmails here', studentWithEmails);

  return studentWithEmails.map((student) => (
    <StudentEmailSchoolAssignment
      student={student}
      setStudentWithEmails={setStudentWithEmails}
      {...restProps}
    />
  ));
}

function StudentEmailSchoolAssignment({
  student,
  schoolsOptions,
  addNewSchool,
  setStudentWithEmails,
  isAdminUser,
  isTeacher,
}) {
  const [showNewSchoolForm, setShowNewSchoolForm] = useState(false);
  const [newSchool, setNewSchool] = useState({
    name: '',
    location: {},
    displayName: '',
  });

  const handleOnChange = (e) => {
    setNewSchool((newSchool) => ({
      ...newSchool,
      [e.target.name]: e.target.value,
    }));
  };

  const renderNewSchoolForm = () => {
    return (
      <div className="mt-4 school-form">
        <FloatingInput
          type="text"
          labelName="School name"
          name="name"
          value={newSchool.name}
          onChange={(e) => handleOnChange(e)}
        />
        <LocationPicker
          label="School Location"
          value={newSchool.location}
          onChange={(location) =>
            setNewSchool((newSchool) => ({
              ...newSchool,
              location,
            }))
          }
        />
        <FloatingInput
          type="text"
          labelName="Display name"
          name="displayName"
          value={newSchool.displayName}
          onChange={(e) => handleOnChange(e)}
        />
        <div className="text-right">
          <RectangularButton
            onClick={() => setShowNewSchoolForm(false)}
            noBorder
          >
            Cancel
          </RectangularButton>
          <RectangularButton
            className="ml-2"
            disabled={
              !newSchool.name ||
              !newSchool.location ||
              typeof newSchool.location !== 'object' ||
              Object.keys(newSchool.location).length === 0
            }
            onClick={() => {
              addNewSchool(newSchool, student.email);
              setShowNewSchoolForm(false);
            }}
          >
            Create
          </RectangularButton>
        </div>
      </div>
    );
  };

  const renderDropDown = () => (
    <DropDownWithAction
      value={schoolsOptions.find((sc) => sc.value === student.schoolId)}
      onChange={(selectedOption) => {
        setStudentWithEmails((studentWithEmails) => {
          const students = _.cloneDeep(studentWithEmails);
          const studentWithEmail = students.find(
            (se) => se.email === student.email,
          );

          studentWithEmail.schoolId = selectedOption.value;

          return students;
        });
      }}
      options={schoolsOptions}
      handleCreate={
        isAdminUser || isTeacher ? () => setShowNewSchoolForm(true) : null
      }
    />
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between school-dropdown">
        <span>{student.email}</span>
        <div className="d-flex align-items-center">
          {!showNewSchoolForm && renderDropDown()}
          <Icon
            icon={ICONS.CLOSE}
            className="cursor-pointer ml-3 mt-2"
            onClick={() => {
              setStudentWithEmails((studentWithEmails) => {
                const students = _.cloneDeep(studentWithEmails);

                return students.filter((se) => se.email !== student.email);
              });
            }}
          />
        </div>
      </div>
      {showNewSchoolForm && renderNewSchoolForm()}
    </div>
  );
}

function getConfirmationMessage(confirmationForUser, isTeacher) {
  if (isTeacher && confirmationForUser.isTeacher) {
    return `${confirmationForUser.email} ${
      confirmationForUser.invited
        ? 'has already been invited to be a Teacher'
        : 'is currently a Teacher'
    }.`;
  }

  if (!isTeacher && !confirmationForUser.isTeacher) {
    return `${confirmationForUser.email} ${
      confirmationForUser.invited
        ? 'has already been invited to be a Student'
        : 'is currently a Student'
    }.`;
  }

  if (confirmationForUser.invited && isTeacher) {
    return `${confirmationForUser.email} has already been invited to be a Student. Do you want to switch their invite to be a Teacher?`;
  }

  if (!confirmationForUser.invited && isTeacher) {
    return `${confirmationForUser.email} is currently a Student. Do you want to switch their role to be a Teacher?`;
  }

  if (confirmationForUser.invited && !isTeacher) {
    return `${confirmationForUser.email} has already been invited to be a Teacher. Do you want to switch their invite to be a Student?`;
  }

  if (!confirmationForUser.invited && !isTeacher) {
    return `${confirmationForUser.email} is currently a Teacher. Do you want to switch their role to be a Student?`;
  }
}

function assignSchoolToStudents(studentEmails, teacherEmail, teacherSchoolId) {
  // Function to extract the domain from an email
  function getEmailDomain(email) {
    return email.substring(email.lastIndexOf('@') + 1);
  }

  // Extract teacher's email domain
  const teacherDomain = getEmailDomain(teacherEmail);

  // Array to store the result
  const result = [];

  // Loop through student emails and assign school if domains match
  studentEmails.forEach((email) => {
    const studentDomain = getEmailDomain(email);
    if (
      studentDomain === teacherDomain &&
      !generalDomains.includes(teacherDomain)
    ) {
      result.push({ email: email, schoolId: teacherSchoolId, default: true });
    } else {
      result.push({ email: email, schoolId: null, default: true });
    }
  });

  return result;
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteStudents);
