import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// import { syncHistoryWithStore, routerMiddleware, ConnectedRouter } from "react-router-redux"
import { ConnectedRouter } from 'connected-react-router';
import Pusher from 'pusher-js';
import configureStore from './redux/configureStore';
import createHistory from './redux/createHistory';
import App from './App';
import AdminApp from './AdminApp';
import authProvider from './services/authProvider';
import { isAdmin } from 'services/adminCheck';

const { NODE_ENV, REACT_APP_SENTRY_DSN } = process.env;

if (NODE_ENV === 'production') {
  // Initialize Sentry
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  });
}

JavascriptTimeAgo.locale(en);

Pusher.logToConsole = false;

const pusher = new Pusher('52e58c76896dc57be2df', {
  cluster: 'ap2',
  encrypted: false,
  authEndpoint: `${process.env.REACT_APP_AXIOS_BASE_URL}/api/auth/pusher/auth`,
  auth: {
    headers: {},
  },
});

// import Header from "./components/Header"
// import MainRoutes from "./MainRoutes"

require('./css/averpoint.scss');
// require("./css/style.css");

/*
 * Adding averpoint.root to fix iframe login issue
 */
if (window.top !== window.self && !localStorage.getItem('averpoint.root')) {
  localStorage.setItem('averpoint.root', '{}');
}

const history = createHistory({});

// const finalCreateStore = applyMiddleware(...middleware)(createStore);
// const rootReducerWithRouterState = connectRouter(history)(rootReducer);
// const store = finalCreateStore(rootReducerWithRouterState);

const store = configureStore(history);
const OneSignal = window.OneSignal || [];
// Watch for session change and subscribe to private channel
let currentSession = null;
store.subscribe(() => {
  const prevSession = currentSession;
  const state = store.getState();
  currentSession = state.user && state.user.user;
  if (currentSession !== prevSession) {
    if (currentSession._id) {
      // user logged in
      // console.log('Subscribing to channel');
      pusher.config.auth.headers.Authorization = `Bearer ${currentSession.token}`;
      const channel = pusher.subscribe(`private-${currentSession._id}`);
      channel.bind('my-event', function (data) {
        console.log('Pusher Event Data Received...', data);
        store.dispatch(data);
      });
      OneSignal.push(function () {
        OneSignal.setExternalUserId(currentSession._id);
      });
    } else {
      // user logged out
      // console.log('logging out of channel');
      delete pusher.config.auth.headers.Authorization;
      pusher.unsubscribe(`${currentSession._id}`);
      // OneSignal.push(function() {
      //   OneSignal.removeExternalUserId();
      // });
    }
  }
});

authProvider(store);

// Create an enhanced history that syncs navigation events with the store
// history = syncHistoryWithStore(history, store)
// const routes = createRoutes(store, history)

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {isAdmin ? <AdminApp /> : <App />}
    </ConnectedRouter>
  </Provider>
);

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

render(<Root />, document.getElementById('app'));

// if (module.hot && process.env.NODE_ENV !== "production") {
//   module.hot.accept("./App", () => {
//     render(<Root />, document.getElementById("app"));
//   });
// }

// Send iframe loaded event
if (window.parent) {
  window.parent.postMessage(
    {
      type: 'averpoint:iframe:loaded',
      url: window.location.href,
    },
    '*',
  );
}
