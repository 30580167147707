// only add Redux-Logger on the client-side because it causes problems server-side.
import isNode from 'detect-node';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { createSession } from 'redux-session';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Actions from './actions';
import rootReducer from './reducers';
import simplePromise from './middleware/simple-promise';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';

const { NODE_ENV } = process.env;
const isProduction = NODE_ENV === 'production';

export default (history, initialState) => {
  const promiseMiddleware = simplePromise();
  const middleware = [thunkMiddleware, promiseMiddleware];

  if (!isNode) {
    // middleware.push(createLogger());
    middleware.push(routerMiddleware(history));

    const session = createSession({
      ns: 'averpoint',
      selectState(state) {
        if (state.user.user.token) {
          Cookies.set('averpoint.user.token', state.user.user.token, {
            sameSite: 'None',
            secure: true,
            path: '/',
            domain: isProduction ? '.averpoint.com' : undefined,
            expires: 3650,
          });
        } else {
          Cookies.remove('averpoint.user.token', {
            sameSite: 'None',
            secure: true,
            path: '/',
            domain: isProduction ? '.averpoint.com' : undefined,
            expires: 3650,
          });
        }

        return {
          user: state.user,
          // portfolios: state.portfolios,
          // collections: state.collections,
          // notifications: state.notifications
          walkthrough: state.walkthrough,
        };
      },
      onLoad(state, dispatch) {
        /*
         *  Request token from parent window if used as iframe
         */
        if (window.top === window.self) {
          // console.log('in on load of client - session method');
          let token;

          if (state.user && state.user.user && state.user.user.token) {
            // console.log('User on state - going to restore session');
            token = state.user.user.token;
          }

          if (!token) {
            token = Cookies.get('averpoint.user.token');
          }
          Actions.restoreSession(token, dispatch);

          window.addEventListener('message', (event) => {
            const { data, type } = event.data;

            switch (type) {
              case 'averpoint:request:updateCurrentUser':
                if (
                  state.user.user &&
                  state.user.user.username === data.user.username
                ) {
                  dispatch(Actions.userUpdate(data.user, false));
                }
                break;
            }
          });
        } else {
          window.addEventListener('message', (event) => {
            const { data, type } = event.data;

            switch (type) {
              case 'averpoint:request:token':
                Actions.restoreSession(data.token, dispatch);
                break;
            }
          });

          window.parent.postMessage({ type: 'averpoint:request:token' }, '*');
        }

        // if (state.walkthrough) {
        //   restoreTutorialState(state.walkthrough, dispatch);
        // }
        // Don't start initializing for a new session on load. Set user reducer to initializing to false at beginning.
        // } else {
        //   userActions.startNewSession(dispatch);
        //   console.log("No user on state - no session to restore");
        // }
      },
    });
    middleware.push(session);
  }

  const reduxEnhancer = [];

  if (isProduction) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
      stateTransformer: (state) => {
        // Do not send any state
        return {};
      },
    });
    reduxEnhancer.push(sentryReduxEnhancer);
  }

  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware), ...reduxEnhancer),
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
