import * as Actions from '../actions/index';

export default function sources(
  state = {
    sourceList: [],
    source: null,
    group: null,
    loading: false,
    sourceErrorMessage: null,
  },
  action,
) {
  switch (action.type) {
    case Actions.GET_SOURCE: {
      return {
        ...state,
        loading: true,
        sourceErrorMessage: null,
      };
    }
    case Actions.GET_SOURCE_SUCCESS: {
      console.log('GET_SOURCE_SUCCESS', action.source);
      return {
        ...state,
        source: action.source.source,
        group: action.source.group,
        sourceErrorMessage: null,
        loading: false,
      };
    }
    case Actions.GET_SOURCE_FAIL: {
      return {
        ...state,
        source: null,
        group: null,
        sourceErrorMessage: action.error,
        loading: false,
      };
    }
    case Actions.LOAD_SOURCE: {
      let sourceList = state.sourceList;
      let currentIndex = -1;
      sourceList.forEach((sourceObject, index) => {
        if (sourceObject._id == action.source._id) {
          currentIndex = index;
        }
      });
      if (currentIndex == -1) {
        sourceList.push(action.source);
      } else {
        sourceList[currentIndex] = action.source;
      }

      return Object.assign({}, state, {
        sourceList,
        sourceErrorMessage: null,
      });
    }
    case Actions.LOAD_SOURCE_NAMES: {
      return Object.assign({}, state, {
        sourceList: action.sourceList,
        sourceErrorMessage: null,
      });
    }
    case Actions.LOAD_SUBSCRIPTION_VERIFICATIONS: {
      return Object.assign({}, state, {
        subscriptionVerifications: action.subscriptionVerifications,
      });
    }
    case Actions.REMOVE_SUBSCRIPTION_VERIFICATIONS: {
      return Object.assign({}, state, {
        subscriptionVerifications: (
          state.subscriptionVerifications || []
        ).filter((s) => s._id !== action.subscriptionId),
      });
    }
    case Actions.LOAD_REQUESTED_SOURCES: {
      return Object.assign({}, state, {
        requestedSources: action.requestedSources,
      });
    }
    case Actions.REMOVE_REQUESTED_SOURCES: {
      return Object.assign({}, state, {
        requestedSources: (state.requestedSources || []).filter(
          (s) => s._id !== action.sourceId,
        ),
      });
    }
    case Actions.LOAD_ADMIN_SOURCES: {
      return Object.assign({}, state, {
        adminSources: action.adminSources,
      });
    }
    case Actions.LOAD_ADMIN_ARTICLES: {
      return Object.assign({}, state, {
        adminArticles: action.adminArticles,
      });
    }
    case Actions.UPDATE_ADMIN_SOURCE: {
      const adminSources = JSON.parse(JSON.stringify(state.adminSources || []));

      const sourceIndex = adminSources.findIndex(
        (s) => s.sourceName === action.sourceName,
      );

      const sourceDetails = {
        ...adminSources[sourceIndex],
        ...action.response,
      };

      adminSources.splice(sourceIndex, 1, sourceDetails);

      return Object.assign({}, state, {
        adminSources,
      });
    }
    case Actions.UPDATE_ADMIN_ARTICLE: {
      const adminArticles = JSON.parse(JSON.stringify(state.adminArticles));

      const articleIndex = adminArticles.findIndex(
        (s) => s._id === action.articleMediaCollectionId,
      );

      const articleDetails = {
        ...adminArticles[articleIndex],
        browserExtensionStatus: action.componentReviews,
        browserExtensionChecked: new Date(),
      };

      adminArticles.splice(articleIndex, 1, articleDetails);

      return Object.assign({}, state, {
        adminArticles,
      });
    }
    case Actions.REMOVE_ADMIN_ARTICLE: {
      const adminArticles = JSON.parse(JSON.stringify(state.adminArticles));

      const adminArticlesCopy = adminArticles.filter(
        (s) => s._id !== action.pointId,
      );

      return Object.assign({}, state, {
        adminArticles: adminArticlesCopy,
      });
    }
    case Actions.LOAD_SOURCE_ERRORS: {
      return Object.assign({}, state, {
        sourceErrors: action.sourceErrors,
      });
    }
    case Actions.RESOLVE_SOURCE_ERROR: {
      const sourceErrors = JSON.parse(JSON.stringify(state.sourceErrors || []));

      const sourceError =
        sourceErrors.find((sourceError) => sourceError._id === action.id) || {};

      sourceError.resolutionText = action.resolutionText;
      sourceError.resolved = true;

      return Object.assign({}, state, {
        sourceErrors,
      });
    }
    case Actions.FIX_SOURCE_ERROR: {
      const sourceErrors = JSON.parse(JSON.stringify(state.sourceErrors));

      return Object.assign({}, state, {
        sourceErrors: sourceErrors.filter(
          (sourceError) => sourceError._id !== action.id,
        ),
      });
    }
    default:
      return state;
  }
}
