import axios from 'axios';
import { toast } from 'components/elements/molecules/Toast/Toast';

export function getVocabularyWords(
  searchQuery,
  origin,
  page,
  pageSize,
  sortBy,
  sortOrder,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/vocabularyWords/getVocabularyWords',
        params: {
          searchTerm: searchQuery,
          page,
          limit: pageSize,
          origin,
          sortBy,
          sortOrder,
        },
      })
        .then((response) => {
          console.log('getVocabularyWords responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getVocabularyWords responded error');
          console.log(error);
          toast('Error occurred while getting vocabulary words', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function createVocabularyWords(word, definitions) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/vocabularyWords/createVocabularyWords',
        data: { word, definitions },
      })
        .then((response) => {
          console.log('createVocabularyWords responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('createVocabularyWords responded error');
          console.log(error);
          toast('Error occurred while creating vocabulary word', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function updateVocabularyWords(id, word, definitions) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/vocabularyWords/updateVocabularyWords',
        data: { id, word, definitions },
      })
        .then((response) => {
          console.log('updateVocabularyWords responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('updateVocabularyWords responded error');
          console.log(error);
          toast('Error occurred while updating vocabulary word', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function getVocabularyWordsForUser(
  scope,
  page,
  pageSize,
  searchQuery,
  sortBy,
  sortOrder,
  isList,
  isShuffle,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/vocabularyWords/getVocabularyWordsForUser',
        params: {
          scope,
          page,
          limit: pageSize,
          searchQuery,
          sortBy,
          sortOrder,
          isList,
          isShuffle,
        },
      })
        .then((response) => {
          console.log('getVocabularyWordsForUser responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getVocabularyWordsForUser responded error');
          console.log(error);
          toast('Error occurred while getting vocabulary words', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function markVocabularyWordAsLearning(vocabularyWordId, page) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/vocabularyWords/markVocabularyWordAsLearning',
        data: { vocabularyWordId, page },
      })
        .then((response) => {
          console.log('markVocabularyWordAsLearning responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('markVocabularyWordAsLearning responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function getVocabularyTestsForUser(
  scope,
  page,
  pageSize,
  vocabularyTestId,
  isShuffle,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/vocabularyWords/getVocabularyTestsForUser',
        params: {
          scope,
          page,
          limit: pageSize,
          vocabularyTestId,
          isShuffle,
        },
      })
        .then((response) => {
          console.log('getVocabularyTestsForUser responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getVocabularyTestsForUser responded error');
          console.log(error);
          toast('Error occurred while getting vocabulary test', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function answerVocabularyQuestion(vocabularyQuestionId, answers) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/vocabularyWords/answerVocabularyQuestion',
        data: { vocabularyQuestionId, answers },
      })
        .then((response) => {
          console.log('answerVocabularyQuestion responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('answerVocabularyQuestion responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function submitVocabularyTest(vocabularyTestId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/vocabularyWords/submitVocabularyTest',
        data: { vocabularyTestId },
      })
        .then((response) => {
          console.log('submitVocabularyTest responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('submitVocabularyTest responded error');
          console.log(error);
          toast('Error occurred while submitting vocabulary test', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function getVocabularyTestsList(
  scope,
  page,
  pageSize,
  searchQuery,
  sortBy,
  sortOrder,
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/vocabularyWords/getVocabularyTestsList',
        params: {
          scope,
          page,
          limit: pageSize,
          searchTerm: searchQuery,
          sortBy,
          sortOrder,
        },
      })
        .then((response) => {
          console.log('getVocabularyTestsList responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getVocabularyTestsList responded error');
          console.log(error);
          toast('Error occurred while submitting vocabulary test', {
            type: 'error',
          });
          reject();
        });
    });
  };
}
