import _ from 'lodash';

export default function filterInvites(emails, course, isTeacher) {
  const invitedStudentEmails = course.invitedStudents.map((s) => s.email);
  const invitedTeacherEmails = course.invitedTeachers.map((t) => t.email);

  const studentEmails = course.students.map((student) => student.email);
  const teacherEmails = course.teachers.map((teacher) => teacher.email);

  // Check if user is already invited as student
  const alreadyInvitedAsStudent = _.intersection(invitedStudentEmails, emails);

  // Check if user is already invited as teacher
  const alreadyInvitedAsTeacher = _.intersection(invitedTeacherEmails, emails);

  // Check if user is already a student of course
  const alreadyStudentOfCourse = _.intersection(studentEmails, emails);

  // Check if user is already a student of course
  const alreadyTeacherOfCourse = _.intersection(teacherEmails, emails);

  const alreadyInvited = [
    ...alreadyInvitedAsStudent,
    ...alreadyInvitedAsTeacher,
    ...alreadyStudentOfCourse,
    ...alreadyTeacherOfCourse,
  ];

  const filteredInvites = emails.filter(
    (invitedEmail) => !alreadyInvited.includes(invitedEmail),
  );

  if (isTeacher) {
    return {
      filteredInvites,
      alreadyInvited: [
        ...alreadyInvitedAsStudent.map((email) => ({
          email,
          invited: true,
          isStudent: true,
        })),
        ...alreadyStudentOfCourse.map((email) => ({
          email,
          invited: false,
          isStudent: true,
        })),
        ...alreadyInvitedAsTeacher.map((email) => ({
          email,
          invited: false,
          isTeacher: true,
        })),
        ...alreadyTeacherOfCourse.map((email) => ({
          email,
          invited: false,
          isTeacher: true,
        })),
      ],
    };
  }

  return {
    filteredInvites,
    alreadyInvited: [
      ...alreadyInvitedAsTeacher.map((email) => ({
        email,
        invited: true,
        isTeacher: true,
      })),
      ...alreadyTeacherOfCourse.map((email) => ({
        email,
        invited: false,
        isTeacher: true,
      })),
      ...alreadyInvitedAsStudent.map((email) => ({
        email,
        invited: true,
        isStudent: true,
      })),
      ...alreadyStudentOfCourse.map((email) => ({
        email,
        invited: false,
        isStudent: true,
      })),
    ],
  };
}
