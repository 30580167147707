import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { generateUniqueId, getFileTypeFromUrl } from 'services';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import { isValidUrl } from 'helpers/validation';
import Editor from 'components/elements/molecules/Editor/Editor';
import moment from 'moment';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import Reading from './Reading';
import sourceFormatOptions from 'config/sourceFormat';
import DropDown from 'components/elements/atoms/DropDown/DropDown';

import './ManageReadings.scss';
import _ from 'lodash';

const REACT_APP_AVERPOINT_READER_PAGE =
  process.env.REACT_APP_AVERPOINT_READER_PAGE;

function ManageReadings({
  courseId,
  topicId,
  isReference = false,
  readings,
  addReading,
  clearAddedReadings,
  updateReading,
  deleteReading,
  onClose,
  actions,
  reading,
  questionsByTopicId,
  openManageQuestionsModal,
  addPendingSourceInfoReading,
}) {
  const [url, setUrl] = useState('');
  const [unapprovedSources, setUnapprovedSources] = useState([]);
  const [referenceSources, setReferenceSources] = useState([]);
  const [readingFormat, setReadingFormat] = useState('url');
  const [pdfFileName, setPdfFileName] = useState('');
  const [text, setText] = useState('');
  const [headline, setHeadline] = useState('');
  const [sourceAuthor, setSourceAuthor] = useState('');
  const [date, setDate] = useState('');
  const [sourceName, setSourceName] = useState('');
  const [updatedSourceName, setUpdatedSourceName] = useState('');
  const [sourceFormat, setSourceFormat] = useState(null);
  const [step, setStep] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [lookingUpURL, setLookingUpURL] = useState(false);
  const [checkingFileType, setCheckingFileType] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [s3PdfFilename, setS3PdfFileName] = useState(null);
  const pdfReadingFile = useRef(null);
  const [requiredFields, setRequiredFields] = useState(null);
  const readingIdRef = useRef(null);

  const quizQuestions = questionsByTopicId[topicId] || [];

  const validUrl = isValidUrl(url);

  const isUpdating = !!reading;

  useEffect(async () => {
    try {
      readingIdRef.current = await generateUniqueId();
    } catch (error) {
      console.log('Error while generating unique id', error);
    }
  }, []);

  useEffect(() => {
    if (reading) {
      setReadingFormat(reading.readingFormat);
      if (reading.readingFormat === 'pdf') {
        setStep(1);
      }
      setUrl(reading.sourceURL || reading.url); // Either reading.url or reading.sourceURL
      setHeadline(reading.title);
      actions
        .getTopicReadingText(reading.pointId, reading._id)
        .then((response) => {
          if (response && response.response) {
            const readingText = response.response;
            const defaultValueOption = sourceFormatOptions.find(
              (s) => s.value === readingText.sourceFormat,
            );

            setSourceName(readingText.sourceName);
            setSourceFormat(defaultValueOption);
            setDate(readingText.sourceDate);
            setSourceAuthor(readingText.sourceAuthor);
            setS3PdfFileName(readingText.s3PdfFilename);
            setText(readingText.text);
          }
        });
    }
  }, [reading]);

  useEffect(async () => {
    try {
      if (!url || !validUrl) {
        return;
      }

      if (isUpdating) {
        return;
      }

      setCheckingFileType(true);

      let isHttp = false;
      let readingUrl = url;

      if (url.startsWith('http://')) {
        isHttp = true;
        readingUrl = url.replace('http://', 'https://');
      }

      const fileType = await getFileTypeFromUrl(readingUrl);

      setCheckingFileType(false);

      let isPdf = fileType.includes('pdf');

      // Handle regular URL
      if (!isPdf) {
        if (readingFormat === 'url') {
          const _id = await generateUniqueId();
          await addReading(courseId, topicId, {
            _id,
            url: readingUrl,
            new: true,
            isReference,
            isHttp,
            readingFormat,
          });

          setUrl('');
        } else {
          if (!headline) {
            lookupURL(readingUrl);
          }
        }

        return;
      }

      // Handle PDF
      if (readingFormat === 'url') {
        // Move to readingFormat PDF...
        // And Continue above process...
        setPdfUrl(readingUrl);
        setReadingFormat('pdf');
        setUrl('');

        return;
      }
    } catch (error) {
      console.log('Error while processing URL');
    } finally {
      setCheckingFileType(false);
    }
  }, [url]);

  useEffect(async () => {
    try {
      const validPdfUrl = isValidUrl(pdfUrl);

      if (!pdfUrl || !validPdfUrl) {
        return;
      }

      setUploadMessage('Processing PDF');

      const fileType = await getFileTypeFromUrl(pdfUrl);

      let isPdf = fileType.includes('pdf');

      if (!isPdf) {
        toast(
          `File type ${fileType} not supported. Please provide a PDF file.`,
          {
            type: 'error',
          },
        );

        setPdfUrl('');
        setUploadMessage(null);

        return;
      }

      const data = new FormData();
      data.append('url', pdfUrl);
      data.append('courseId', courseId);
      data.append('topicId', topicId);

      setUploadMessage('Processing PDF');

      actions
        .parsePdfReadingFile(readingIdRef.current, courseId, topicId, data)
        .then((response) => {
          const currentReading = response;

          setHeadline(currentReading.sourceTitle);
          setSourceName(currentReading.sourceName);
          setSourceFormat(currentReading.sourceFormat);
          setDate(currentReading.sourceDate);
          setSourceAuthor(currentReading.sourceAuthor);
          setS3PdfFileName(currentReading.s3PdfFilename);
          setUploadMessage(null);

          setStep(1);
        })
        .catch((error) => {
          setUploadMessage(null);
          console.log(error);
          onClose();
        });
    } catch (error) {
      console.log('Error while processing URL');
    }
  }, [pdfUrl]);

  const lookupURL = async (url) => {
    setLookingUpURL(true);

    try {
      const res = await actions.lookupURL(url);

      if (res.sourceTitle) {
        setHeadline(res.sourceTitle);
      }

      if (res.sourceAuthor) {
        setSourceAuthor(res.sourceAuthor);
      }

      if (res.sourceName) {
        setSourceName(res.sourceName);
      }

      if (res.sourceFormat) {
        const defaultValueOption = sourceFormatOptions.find(
          (s) => s.value === res.sourceFormat,
        );

        setSourceFormat(defaultValueOption);
      }

      if (res.sourceDate) {
        setDate(moment(res.sourceDate).format('yyyy-MM-DD'));
      }
    } catch (error) {
      setErrorMessage(error.error);
    } finally {
      setLookingUpURL(false);
    }
  };

  // useEffect(() => {
  //   if (readingFormat === 'pdf') {
  //     if (s3PdfFilename && sourceFormat && sourceName && headline) {
  //       onAdd();
  //     }
  //   }
  // }, [uploadMessage]);

  const updateReadingHandler = (reading) => {
    updateReading(courseId, topicId, reading);
  };

  const deleteReadingHandler = (reading) => {
    deleteReading(courseId, topicId, reading);
  };

  const onAdd = async () => {
    if (readingFormat === 'url' && !isUpdating && (!url || !validUrl)) {
      return;
    }

    const readingSourceName = sourceName || updatedSourceName;

    if (
      readingFormat === 'text' &&
      (!text || !sourceFormat || !readingSourceName || !headline)
    ) {
      return;
    }

    if (
      readingFormat === 'pdf' &&
      (!s3PdfFilename || !sourceFormat || !readingSourceName || !headline)
    ) {
      setLoading(false);
      return;
    }

    if (
      readingFormat === 'url' &&
      isUpdating &&
      (!sourceFormat || !readingSourceName || !headline)
    ) {
      return;
    }

    try {
      setLoading(true);

      let actionPromise;
      if (isUpdating) {
        actionPromise = actions.updateReading(
          reading._id,
          reading.pointId,
          topicId,
          courseId,
          text,
          headline,
          sourceAuthor,
          date,
          readingSourceName,
          sourceFormat && sourceFormat.value
            ? sourceFormat.value
            : sourceFormat,
          updatedSourceName,
        );
      } else {
        let _id = readingIdRef.current || (await generateUniqueId());

        actionPromise = actions.addReading(
          topicId,
          courseId,
          url || pdfUrl,
          _id,
          readingFormat,
          text,
          headline,
          sourceAuthor,
          date,
          readingSourceName,
          isReference,
          s3PdfFilename,
          null,
          sourceFormat && sourceFormat.value
            ? sourceFormat.value
            : sourceFormat,
          updatedSourceName,
        );
      }

      await actionPromise;
    } catch (error) {
      console.error('Error while generating unique id', error);
    } finally {
      setLoading(false);
      clearAddedReadings(courseId, topicId);
      onClose();
    }
  };

  function renderReadingFormat() {
    if (readingFormat === 'url' && step === 1) {
      return null;
    }

    return (
      <div className="d-flex align-items-center">
        <input
          type="radio"
          className="radio-button"
          checked={readingFormat === 'url'}
          onChange={(e) => {
            setReadingFormat('url');
          }}
          disabled={isUpdating && readingFormat !== 'url'}
        />
        <span className="font-size-small">Link</span>
        <input
          type="radio"
          className="radio-button ml-4"
          checked={readingFormat === 'text'}
          onChange={(e) => {
            setReadingFormat('text');
          }}
          disabled={isUpdating && readingFormat !== 'text'}
        />
        <span className="font-size-small">Text</span>
        <input
          type="radio"
          className="radio-button ml-4"
          checked={readingFormat === 'pdf'}
          onChange={(e) => {
            setReadingFormat('pdf');
          }}
          disabled={isUpdating && readingFormat !== 'pdf'}
        />
        <span className="font-size-small">Pdf</span>
      </div>
    );
  }

  function renderTextEditor() {
    if (
      readingFormat === 'url' ||
      readingFormat === 'pdf' ||
      (readingFormat === 'text' && step === 1)
    ) {
      return null;
    }

    return (
      <Editor
        onChange={(text) => {
          setText(text);
        }}
        placeholder="Type text here"
        html={text}
      />
    );
  }

  function renderStep() {
    if (readingFormat !== 'text') {
      return null;
    }

    return <span className="mr-4">Step {step + 1} of 2</span>;
  }

  function renderAddButton() {
    if (loading) {
      return (
        <div className="text-center">
          <div
            className="fa fa-spin fa-spinner text-primary"
            style={{ fontSize: '32px' }}
          />
        </div>
      );
    }

    let buttonText;
    let validFields = true;
    let requiredFields = [];

    if (readingFormat === 'url' && !isUpdating) {
      buttonText = step === 0 ? 'Continue' : 'Done';
    } else if (readingFormat === 'pdf') {
      buttonText = 'Add';

      const readingSourceName = sourceName || updatedSourceName;

      if (!readingSourceName || !sourceFormat || !headline) {
        validFields = false;
        requiredFields = {
          sourceName: readingSourceName,
          sourceFormat,
          headline,
        };
      }
    } else if (step === 1 || (readingFormat === 'url' && isUpdating)) {
      const readingSourceName = sourceName || updatedSourceName;

      if (!readingSourceName || !sourceFormat || !headline) {
        validFields = false;
        requiredFields = {
          sourceName: readingSourceName,
          sourceFormat,
          headline,
        };
      }

      buttonText = isUpdating ? 'Update' : 'Add';
    } else {
      if (!text) {
        validFields = false;
        requiredFields = { text };
      }

      buttonText = 'Next';
    }

    return (
      <RectangularButton
        className="next-button"
        onClick={() => {
          setErrorMessage('');

          if (readingFormat === 'pdf' && uploadMessage === 'Processing PDF') {
            addPendingSourceInfoReading({
              courseId,
              topicId,
              readingId: readingIdRef.current,
              pdfUrl,
              readingFormat: 'pdf',
              isReference,
              pdfFileName,
            });
            setLoading(false);
            onClose();
            return;
          }

          if (!validFields) {
            setRequiredFields(
              Object.keys(requiredFields).filter(
                (field) => !requiredFields[field],
              ),
            );
            return;
          }

          setRequiredFields(null);

          if (
            readingFormat === 'pdf' ||
            (readingFormat === 'url' && isUpdating)
          ) {
            onAdd();
          } else if (step === 0) {
            setStep(1);
          } else if (readingFormat === 'url') {
            clearAddedReadings(courseId, topicId);
            onClose();
          } else {
            onAdd();
          }
        }}
        // disabled={readingFormat === 'pdf' && !validFields}
      >
        {buttonText}
      </RectangularButton>
    );
  }

  function renderBackButton() {
    if (readingFormat === 'url' || step === 0) {
      return null;
    }

    return (
      <ActionLink
        linkClassName="mr-4 d-flex align-items-center av-blue"
        iconType="averPointIcon"
        iconName="BACK"
        onClick={() => {
          setStep(0);
        }}
      >
        Back
      </ActionLink>
    );
  }

  function renderURL() {
    if (
      isUpdating ||
      (readingFormat === 'text' && step === 0) ||
      readingFormat === 'pdf' ||
      (readingFormat === 'url' && step === 1)
    ) {
      return null;
    }

    return (
      <div className="d-flex align-items-center mb-4">
        <div className="reading-url-edit">
          <FloatingInput
            className="mb-0"
            value={url || ''}
            placeholder={
              readingFormat === 'url'
                ? `Paste the url to add ${
                    readings.length > 0 ? 'another' : 'a'
                  } ${isReference ? 'context' : 'reading'}`
                : 'URL'
            }
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            onPaste={(e) => {
              setUrl(e.target.value);
            }}
            disabled={isUpdating}
            error={url && !validUrl ? 'Invalid Url' : ''}
          />
          {(lookingUpURL || checkingFileType) && (
            <span className="fa fa-spin fa-spinner position-absolute lookup-spinner" />
          )}
          {!lookingUpURL &&
            readingFormat === 'text' &&
            !headline &&
            !checkingFileType && (
              <RectangularButton
                className="position-absolute lookup-button"
                onClick={() => lookupURL(url)}
                disabled={!url || !validUrl}
              >
                Lookup
              </RectangularButton>
            )}
        </div>
      </div>
    );
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const data = new FormData();
      data.append('file', file);
      data.append('courseId', courseId);
      data.append('topicId', topicId);

      setUploadMessage('Processing PDF');
      setPdfFileName(file.name);

      actions
        .parsePdfReadingFile(readingIdRef.current, courseId, topicId, data)
        .then((response) => {
          const currentReading = response;

          setHeadline(currentReading.sourceTitle);
          setSourceName(currentReading.sourceName);
          setSourceFormat(currentReading.sourceFormat);
          setDate(currentReading.sourceDate);
          setSourceAuthor(currentReading.sourceAuthor);
          setS3PdfFileName(currentReading.s3PdfFilename);
          setUploadMessage(null);

          setStep(1);
        })
        .catch((error) => {
          console.log(error);
          setUploadMessage(null);
          onClose();
        });
    }
  };

  function showPdfFileStatus() {
    if (!uploadMessage) {
      return null;
    }

    return <span>{uploadMessage}</span>;
  }

  function showPdfUploadProcess() {
    return (
      <div
        className="fa fa-spin fa-spinner text-primary ml-2"
        style={{ fontSize: '16px' }}
      />
    );
  }

  function renderPdfUpload() {
    if (
      readingFormat === 'url' ||
      readingFormat === 'text' ||
      loading ||
      reading
    ) {
      return null;
    }

    if (!uploadMessage) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center mt-4 pdf-file-upload-section">
          <input
            ref={pdfReadingFile}
            className="d-none"
            type="file"
            onChange={handleFileChange}
            accept=".pdf"
          />
          <div
            className="file-upload-container"
            onClick={() => pdfReadingFile.current.click()}
          >
            <div>
              <Icon icon={ICONS.UPLOAD_FILE} />
            </div>
            <div className="file-upload-title">Choose file to upload</div>
            <div className="av-gray supported-formats">
              Supported formats: pdf
            </div>
          </div>
          <div className="or-divider">OR</div>
          <div className="file-upload-title w-50">Import from URL</div>
          <FloatingInput
            className="mb-0"
            value={pdfUrl || ''}
            placeholder="Add pdf URL"
            onChange={(e) => {
              setPdfUrl(e.target.value);
            }}
            onPaste={(e) => {
              setPdfUrl(e.target.value);
            }}
            disabled={isUpdating}
          />
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center my-4">
        <div className="av-green">
          {showPdfFileStatus()}
          {showPdfUploadProcess()}
        </div>
      </div>
    );
  }

  function renderHeadline() {
    if (
      (readingFormat === 'url' && !isUpdating) ||
      (readingFormat === 'text' && step === 0) ||
      (readingFormat === 'pdf' && step === 0)
    ) {
      return null;
    }

    return (
      <FloatingInput
        className="mb-4 mt-4"
        value={headline}
        labelName="Headline*"
        onChange={(e) => {
          setHeadline(e.target.value);
        }}
      />
    );
  }

  function renderAuthors() {
    if (
      (readingFormat === 'url' && !isUpdating) ||
      (readingFormat === 'text' && step === 0) ||
      (readingFormat === 'pdf' && step === 0)
    ) {
      return null;
    }

    return (
      <FloatingInput
        className="mb-0"
        value={sourceAuthor}
        labelName="Author"
        onChange={(e) => {
          setSourceAuthor(e.target.value);
        }}
      />
    );
  }

  function renderDate() {
    if (
      (readingFormat === 'url' && !isUpdating) ||
      (readingFormat === 'text' && step === 0) ||
      (readingFormat === 'pdf' && step === 0)
    ) {
      return null;
    }

    return (
      <FloatingInput
        className="mb-0"
        value={date}
        labelName="Date"
        onChange={(e) => {
          setDate(e.target.value);
        }}
        type="date"
      />
    );
  }

  function renderSourceName() {
    if (
      (readingFormat === 'url' && !isUpdating) ||
      (readingFormat === 'text' && step === 0) ||
      (readingFormat === 'pdf' && step === 0)
    ) {
      return null;
    }

    return (
      <FloatingInput
        className="mb-0"
        value={updatedSourceName || sourceName}
        labelName="Source Name*"
        onChange={(e) => {
          setUpdatedSourceName(e.target.value);
        }}
      />
    );
  }

  function renderSourceFormat() {
    if (
      (readingFormat === 'url' && !isUpdating) ||
      (readingFormat === 'text' && step === 0) ||
      (readingFormat === 'pdf' && step === 0)
    ) {
      return null;
    }

    return (
      <div className="dropdown">
        <DropDown
          labelName="Source Format*"
          value={sourceFormat}
          onChange={(selectedOption) => setSourceFormat(selectedOption)}
          options={sourceFormatOptions}
        />
      </div>
    );
  }

  function renderActions() {
    return (
      <div className="d-flex align-items-center justify-content-end ml-auto">
        {renderStep()}
        {renderBackButton()}
        {renderAddButton()}
      </div>
    );
  }

  function renderShowRequireFieldsMessage() {
    if (!requiredFields || requiredFields.length === 0) {
      return null;
    }

    return (
      <div className="d-flex align-items-center justify-content-end av-red">
        {joinWithAnd(requiredFields.map((field) => _.startCase(field)))}{' '}
        {requiredFields.length > 1 ? 'are' : 'is'} required.
      </div>
    );
  }

  function showAddedReadings() {
    if (readingFormat !== 'url' || readings.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <table>
          {step === 1 && (
            <tr className="readings-container">
              <td className="reading-status-column"></td>
              <td className="readings"></td>
              <td className="create-quiz-column">Quiz</td>
            </tr>
          )}
          <tbody>
            {readings
              .filter((r) => r.loading)
              .filter((reading) => !!reading.isReference === isReference)
              .map((reading) => (
                <Reading
                  key={reading._id}
                  topicId={topicId}
                  courseId={courseId}
                  reading={reading}
                  updateReading={updateReadingHandler}
                  deleteReading={deleteReadingHandler}
                  actions={actions}
                  setUnapprovedSources={setUnapprovedSources}
                  setReferenceSources={setReferenceSources}
                  addPendingSourceInfoReading={addPendingSourceInfoReading}
                  // showQuiz={step === 1}
                  // openQuestionsModal={() => openManageQuestionsModal([reading])}
                />
              ))}
            {readings
              .filter((r) => !r.loading)
              .filter((reading) => !!reading.isReference === isReference)
              .map((reading) => (
                <Reading
                  key={reading._id}
                  topicId={topicId}
                  courseId={courseId}
                  reading={reading}
                  updateReading={updateReadingHandler}
                  deleteReading={deleteReadingHandler}
                  actions={actions}
                  setUnapprovedSources={setUnapprovedSources}
                  setReferenceSources={setReferenceSources}
                  showQuiz={step === 1}
                  openQuestionsModal={() =>
                    openManageQuestionsModal([
                      { ...reading, courseId, topicId },
                    ])
                  }
                  quizQuestions={quizQuestions}
                  addPendingSourceInfoReading={addPendingSourceInfoReading}
                />
              ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  const showModalTitle = () => {
    if (readingFormat === 'url' && step === 1) {
      return <span>Add quiz</span>;
    }

    return (
      <span>
        {isUpdating ? 'Update' : 'Add'}{' '}
        {isReference ? 'Context Readings' : 'Readings'}
      </span>
    );
  };

  return (
    <Modal show={true} className="manage-readings-modal" bsSize="lg">
      <Modal.Body>
        <div className="header mb-4">
          {showModalTitle()}
          <Button
            bsStyle="link"
            className="btn-close"
            onClick={() => {
              clearAddedReadings(courseId, topicId);
              onClose();
            }}
          >
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="manage-readings-component mb-5">
          {renderReadingFormat()}
          {renderPdfUpload()}
          {renderURL()}
          {renderHeadline()}
          <div className="row mb-4">
            <div className="col-md-6">{renderAuthors()}</div>
            <div className="col-md-6">{renderSourceFormat()}</div>
          </div>
          <div className="row">
            <div className="col-md-6">{renderSourceName()}</div>
            <div className="col-md-6">{renderDate()}</div>
          </div>
          {renderTextEditor()}
          {/* {renderAddButton()} */}
          {showAddedReadings()}
        </div>

        <div className="av-gray font-size-small mt-4 d-flex align-items-center">
          {/* The Readings will continue to process in the background{' '} */}
          {/* <RectangularButton
            className="next-button ml-auto"
            disabled={!readings.length}
            onClick={() => done()}
          >
            Done
          </RectangularButton> */}
          {renderActions()}
        </div>
        {errorMessage && (
          <div className="d-flex align-items-center justify-content-end text-right av-red">
            {errorMessage}
          </div>
        )}
        {renderShowRequireFieldsMessage()}
        {unapprovedSources.length > 0 && (
          <div className="d-flex av-camel font-size-small mt-2">
            <div className="status-check yellow mr-2">
              <Icon icon={ICONS.CHECK} size={10} />
            </div>
            The source {unapprovedSources.join(', ')} is not yet on AverPoint's
            approved list, but your reading will still work for students in your
            class. We'll evaluate the source, and get back to you with
            questions.
          </div>
        )}
        {referenceSources.length > 0 && (
          <div className="d-flex av-camel font-size-small mt-2">
            <div className="status-check yellow mr-2">
              <Icon icon={ICONS.CHECK} size={10} />
            </div>
            The source {referenceSources.join(', ')} is marked as reference
            source on AverPoint's source list. Readings from these sources will
            be added to context readings list.
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

function joinWithAnd(array) {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else {
    const firsts = array.slice(0, array.length - 1);
    const last = array[array.length - 1];
    return firsts.join(', ') + `${array.length > 2 ? ',' : ''} and ` + last;
  }
}

function mapStateToProps(state, ownProps) {
  const course = state.classRoom.courses.find(
    (c) => c.courseId === ownProps.courseId,
  );
  const topic = course.topics.find((t) => t._id === ownProps.topicId);
  const readings = (topic.readingStatistics || []).filter((r) => r.new);

  return {
    readings,
    questionsByTopicId: state.classRoom.questionsByTopicId || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    addReading: (courseId, topicId, reading) => {
      dispatch(Actions.addReadingToTopic(courseId, topicId, reading));
    },
    addPendingSourceInfoReading: (data) => {
      dispatch(Actions.addPendingSourceInfoReading(data));
    },
    clearAddedReadings: (courseId, topicId) => {
      dispatch(Actions.clearAddedReadings(courseId, topicId));
    },
    updateReading: (courseId, topicId, reading) => {
      dispatch(Actions.updateReadingToTopic(courseId, topicId, reading));
    },
    deleteReading: (courseId, topicId, reading) => {
      dispatch(Actions.removeReadingFromTopic(courseId, topicId, reading._id));
    },
    openManageQuestionsModal: (readings) => {
      const props = { readings };
      dispatch(Actions.openModal('manage-quiz-questions', props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageReadings);
