import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import MultiChipComponent from '../../elements/molecules/MultiChipComponent/MultiChipComponent';

import './ReclassifyCommunityPickedArticle.scss';
import { toast } from 'components/elements/molecules/Toast/Toast';
import TopicSelectButton from 'components/elements/organisms/TopicSelection/TopicSelection';

function ReclassifyCommunityPickedArticle({
  appData,
  pointId,
  pointSourceAnalytics,
  onClose,
  // reclassifyCommunityPickArticle,
}) {
  const [error, setError] = useState('');

  const [sourceAnalytics, setSourceAnalytics] = useState(
    pointSourceAnalytics || {},
  );

  const changeSourceAnalytics = (property, value) => {
    setSourceAnalytics((prevSourceAnalytics) => ({
      ...prevSourceAnalytics,
      [property]: value,
    }));
  };

  const reclassifyArticle = () => {
    axios
      .post('/api/mediaChannel/reclassifyArticle', {
        pointId,
        sourceAnalytics,
      })
      .then(() => {
        // reclassifyCommunityPickArticle(pointId, sourceAnalytics);
        toast('Thanks for suggesting new topics. Our team will review.', {
          type: 'success',
        });
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  const { topics = {} } = (appData && appData.topics) || {};

  const [secondLevelTopics, thirdLevelTopics] = useMemo(() => {
    let topicsForSecondLevel = [];

    if (Array.isArray(sourceAnalytics.topic)) {
      const selectedMainTopics = sourceAnalytics.topic;

      for (const mainTopic of selectedMainTopics) {
        topicsForSecondLevel = topicsForSecondLevel.concat(
          Object.keys(topics[mainTopic]),
        );
      }
    } else if (sourceAnalytics.topic) {
      const selectedMainTopics = [sourceAnalytics.topic];

      for (const mainTopic of selectedMainTopics) {
        topicsForSecondLevel = topicsForSecondLevel.concat(
          Object.keys(topics[mainTopic]),
        );
      }
    }

    if (Array.isArray(sourceAnalytics.secondLevelTopic)) {
      const updatedSecondLevelTopic = _.intersection(
        sourceAnalytics.secondLevelTopic,
        topicsForSecondLevel,
      );
      if (
        updatedSecondLevelTopic.length !==
        sourceAnalytics.secondLevelTopic.length
      ) {
        changeSourceAnalytics(
          'secondLevelTopic',
          _.intersection(
            sourceAnalytics.secondLevelTopic,
            topicsForSecondLevel,
          ),
        );
      }
    }

    let topicsForThirdLevel = [];

    if (Array.isArray(sourceAnalytics.secondLevelTopic)) {
      const selectedMainTopics = sourceAnalytics.topic;
      const selectedTopics = sourceAnalytics.secondLevelTopic;

      for (const secondLevelTopic of selectedTopics) {
        for (const mainTopic of selectedMainTopics) {
          if ((topics[mainTopic] || {})[secondLevelTopic]) {
            topicsForThirdLevel = topicsForThirdLevel.concat(
              Object.keys(topics[mainTopic][secondLevelTopic]),
            );
          }
        }
      }
    }

    if (Array.isArray(sourceAnalytics.thirdLevelTopic)) {
      const updatedThirdLevelTopic = _.intersection(
        sourceAnalytics.thirdLevelTopic,
        topicsForThirdLevel,
      );
      if (
        updatedThirdLevelTopic.length !== sourceAnalytics.thirdLevelTopic.length
      ) {
        changeSourceAnalytics(
          'thirdLevelTopic',
          _.intersection(sourceAnalytics.thirdLevelTopic, topicsForThirdLevel),
        );
      }
    }

    return [topicsForSecondLevel, topicsForThirdLevel];
  }, [sourceAnalytics.topic, sourceAnalytics.secondLevelTopic, topics]);

  return (
    <Modal show={true} className="clubhouse-signup-modal">
      <Modal.Body>
        <div className="header">
          <span>Suggest topics for this article</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <div className="multi-chip-component">
          <div className="col-xs-12 mt-3">
            <div className="d-flex">
              <div className="mr-2 mt-3 flex-shrink-0 selection-label text-right">
                Main Topics
              </div>
              <div className="topic-buttons">
                <TopicSelectButton
                  topics={Object.keys(topics).sort((b, a) =>
                    b.localeCompare(a),
                  )}
                  selectedTopics={
                    Array.isArray(sourceAnalytics.topic)
                      ? sourceAnalytics.topic
                      : [sourceAnalytics.topic]
                  }
                  setTopics={(values) => changeSourceAnalytics('topic', values)}
                />
              </div>
            </div>
          </div>
        </div>
        {secondLevelTopics.length > 0 && (
          <div className="row">
            <div className="col-xs-12">
              <div className="d-flex">
                <div className="mr-2 mt-3 flex-shrink-0 selection-label text-right">
                  2nd-level Topics
                </div>
                <div>
                  <TopicSelectButton
                    topics={secondLevelTopics}
                    selectedTopics={sourceAnalytics.secondLevelTopic}
                    setTopics={(values) =>
                      changeSourceAnalytics('secondLevelTopic', values)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {thirdLevelTopics.length > 0 && (
          <div className="row">
            <div className="col-xs-12">
              <div className="d-flex">
                <div className="mr-2 mt-3 flex-shrink-0 selection-label text-right">
                  3rd-level Topics
                </div>
                <div>
                  <TopicSelectButton
                    topics={thirdLevelTopics}
                    selectedTopics={sourceAnalytics.thirdLevelTopic}
                    setTopics={(values) =>
                      changeSourceAnalytics('thirdLevelTopic', values)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="text-center">
          <RectangularButton
            className="next-button mt-3"
            onClick={() => reclassifyArticle()}
          >
            Suggest these topics
          </RectangularButton>
        </div>
        {error && <div className="av-red text-right mt-2">{error}</div>}
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    appData: state.app ? state.app : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reclassifyCommunityPickArticle: (pointId, sourceAnalytics) => {
      dispatch(
        Actions.reclassifyCommunityPickArticle(pointId, sourceAnalytics),
      );
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReclassifyCommunityPickedArticle);
